<template>
  <div class="page">
    <Login ref="login" :callback="init" mode="GST" />
    <div class="search">
      <van-row>
        <van-col span="3" @click="back()"
          ><div class="back-btn">
            <van-icon name="arrow-left" />返回
          </div></van-col
        >
        <van-col span="21">
          <van-search
            v-model="name"
            show-action
            placeholder="请输入搜索关键词"
            ref="keyword"
          >
            <template #action>
              <div @click="search">搜索</div>
            </template></van-search
          >
        </van-col>
      </van-row>
    </div>
    <div class="inst text-short">颁发机构：{{ instName }}</div>
    <div class="cert">
      <div class="title"><van-icon name="notes-o" /> 证书列表</div>
      <van-list
        :finished="finished"
        finished-text="没有更多了"
        offset="0"
        :immediate-check="false"
        @load="retrieveCertList"
      >
        <template #default>
          <van-row class="item" v-for="cert in certList" :key="cert.code">
            <van-col span="18"
              ><van-icon name="arrow" /> {{ cert.name }}</van-col
            >
            <van-col span="6" class="btn">
              <van-tag
                :color="COLOR_M"
                plain
                size="medium"
                v-if="cert.count > 0"
                @click="certTask(cert.code)"
                ><van-icon name="passed" />&nbsp;立即报名</van-tag
              >
              <van-tag
                :color="COLOR_S1"
                plain
                size="medium"
                @click="createApply(cert.code)"
                v-if="cert.count <= 0"
                ><van-icon name="passed" />&nbsp;申请预约</van-tag
              >
            </van-col>
          </van-row>
        </template>
      </van-list>
      <van-row v-if="certList.length <= 0">
        <van-col span="24" class="no-record">
          <van-image
            width="103"
            height="103"
            :src="require('../../../../assets/images/home/no-record.png')"
          ></van-image>
        </van-col>
      </van-row>
    </div>
    <Loading :show="loadingShow" />
  </div>
</template>
<script>
import { Search, Icon, List, Tag } from 'vant'
import Loading from '../../common/Loading.vue'
import Login from '../../intercept/Login.vue'
export default {
  components: {
    Loading: Loading,
    Login: Login,
    [Search.name]: Search,
    [Icon.name]: Icon,
    [List.name]: List,
    [Tag.name]: Tag
  },
  data () {
    return {
      loadingShow: false,
      finished: false,
      issuerCode: '',
      name: '',
      page: { current: 0, size: 50, last: 0 },
      instName: '',
      certList: []
    }
  },
  mounted () {
    var query = this.$route.query
    this.issuerCode = query.issuerCode
    this.$refs.login.validate()
  },
  methods: {
    init () {
      this.retrieveCertList()
      this.retrieveIssuer()
    },
    search () {
      this.page.current = 0
      this.finished = false
      this.certList = []
      this.retrieveCertList()
    },
    async retrieveCertList () {
      if (!this.finished) {
        this.loadingShow = true
        this.page.current = this.page.current + 1
        var pd = { issuerCode: this.issuerCode, name: this.name, current: this.page.current, size: this.page.size }
        const { data: res } = await this.$http.post(this.BMS_URL + '/train/taskAPC/retrieveIssuerCertList', this.$qs.stringify(pd))
        if (res.status === '200') {
          this.certList = this.certList.concat(res.data)
          this.page.last = res.page.last
          if (res.data.length <= 0) {
            this.finished = true
          }
        }
        this.loadingShow = false
      }
    },
    async retrieveIssuer () {
      var pd = { code: this.issuerCode }
      const { data: res } = await this.$http.post(this.BMS_URL + '/data/issuer/retrieveIssuer', this.$qs.stringify(pd))
      if (res.status === '200') {
        var instList = res.data.inst
        var instName = ''
        instList.forEach(element => {
          instName += element.name + '+'
        })
        this.instName = instName.substring(0, instName.length - 1)
      }
    },
    async certTask (code) {
      var key = window.const.global.TRAIN_CERT_CERTTASK_BACK
      window.sessionStorage.setItem(key, window.location.href)
      this.$router.push({ path: '/train/certTask', query: { certCode: code } })
    },
    async createApply (code) {
      var operator = window.sessionStorage.getItem(this.SESSION_OPERATOR)
      var seller = window.sessionStorage.getItem(this.SESSION_SELLER)
      var referee = window.sessionStorage.getItem(this.SESSION_REFEREE)
      var pd = { businessCode: code, groupKey: 'TRA_CERT_TASK', operatorCode: operator, sellerCode: seller, refereeCode: referee }
      const { data: res } = await this.$http.post(this.BMS_URL + '/overall/apply/createApply', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.$dialog.alert({
          title: '提示',
          message: '申请成功,请保持电话畅通'
        })
      } else {
        this.$dialog.alert({
          title: '提交失败',
          message: res.msg
        })
      }
    },
    back () {
      this.$router.push({ path: '/mde/train/home' })
    }
  }
}
</script>
<style lang="less" scoped>
.search {
  .back-btn {
    font-size: 14px;
    font-weight: 600;
    height: 50px;
    line-height: 50px;
  }
  .text {
    color: #fff;
  }
  .module {
    margin: 10px auto;
    text-align: center;
  }
  .btn {
    margin-bottom: 10px;
  }
}
.inst {
  height: 40px;
  padding: 0px 20px;
  margin: 0px auto;
  line-height: 40px;
  background: #eee;
  text-align: center;
  font-size: 15px;
  font-weight: 600;
}
.cert {
  .title {
    height: 40px;
    line-height: 40px;
    text-align: left;
    font-size: 14px;
    font-weight: 600;
    padding-left: 10px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
  }
  .item {
    text-align: left;
    padding-left: 10px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 1px dashed #eee;
    .count {
      font-size: 12px;
      font-weight: 400;
    }
  }
}
.no-record {
  margin-top: 60px;
}
</style>
